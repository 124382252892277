@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;600&display=swap");

.testimonial-section {
  /* fonts */
  --font-cabin: Cabin;
  --font-lato: Lato;
  --font-work-sans: "Work Sans";

  /* font sizes */
  --font-size-50xl: 69px;
  --font-size-xl: 20px;
  --font-size-base: 16px;

  /* Colors */
  --color-whitesmoke: #f2f2f2;
  --color-white: #fff;
  --color-gray-100: #fdfdfd;
  --color-gray-200: #181818;
  --color-gray-300: rgba(0, 0, 0, 0.1);
  --color-dimgray: #595566;

  /* Gaps */
  --gap-xl: 20px;
  --gap-smi: 13px;
  --gap-6xs: 7px;
  --gap-9xs: 4px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-2xl: 21px;
  --padding-12xs: 1px;
  --padding-9xl: 28px;
  --padding-11xl: 30px;

  /* Border radiuses */
  --br-21xl: 40px;
  --br-xl: 20px;
  --br-281xl: 300px;
}
