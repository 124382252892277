.choose-frame-child1 {
  width: 309px;
  height: 205px;
  position: relative;
  border-radius: 40px;
  background-color: #fff;
  display: none;
}
.location-icons {
  width: 70px;
  height: 70px;
  position: relative;
  z-index: 1;
}
.choose-heading-22,
.choose-integer-nec-posuere1 {
  margin: 0;
  position: relative;
  z-index: 1;
}
.choose-heading-22 {
  font-size: inherit;
  line-height: 17px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
}
.choose-integer-nec-posuere1 {
  align-self: stretch;
  font-size: 13px;
  line-height: 22px;
  font-family: Roboto;
  color: #888;
}
.choose-heading-2-results-of-drivers-group {
  width: 245px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
}
.choose-frame-div,
.choose-frame-parent {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.choose-frame-div {
  flex: 1;
  border-radius: 40px;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
  min-width: 265px;
  max-width: 309px;
  min-height: 290px;
  max-height: 309px;
  z-index: 2;
}
.choose-frame-parent {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: left;
  font-size: 18px;
  color: #222;
  font-family: "Work Sans";
}
