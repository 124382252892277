.carousel-p {
  width: 45%;
  margin: 0 auto 50px auto;
}
.carousel-indicators [data-bs-target] {
  border-radius: 100%;
  width: 10px;
  height: 10px;
}
.carousel-control-next-icon {
  background-image: url("../../public/assets/images/right_arrow_circle_icon.png");
  filter: invert(100%) brightness(100%);
}
.carousel-control-prev-icon {
  background-image: url("../../public/assets/images/right_arrow_circle_icon.png");
  filter: invert(100%) brightness(100%);
  transform: scaleX(-1);
}

@media screen and (max-width: 450px) {
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  .carousel-p {
    width: 80%;
    margin: 10px auto 50px auto;
  }
  .carousel-head {
    font-size: 1.5rem !important;
    font-weight: 700;
  }
}
