.page-content-wrapper .page-content {
  margin-left: 0;
  padding: 0;
}

.page-content-wrapper .page-content {
  padding: 0 !important;
}

.newest-auction {
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-family: "Work Sans", sans-serif;
}

.newest-auction > li {
  margin: 0 0 20px;
}

.newest-auction li img {
  float: left;
  width: 200px;
  height: 120px;
}

.newest-auction li .info {
  overflow: hidden;
  background-color: #f5f5f5;
  position: relative;
  height: 120px;
  padding-top: 7px;
  padding-right: 40px;
  text-align: left;
}

.newest-auction li .info .title {
  font-size: 20px;
  margin: 0;
  color: #336699;
  font-weight: 600;
  text-transform: uppercase;
}

.newest-auction li .info .desc {
  font-size: 12pt;
  font-weight: 300;
  margin: 0;
}

.newest-auction li .info a {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
}

.newest-auction li .info .price {
  font-size: 17pt;
  margin: 0;
  color: #c00;
  font-weight: 600;
}

.newest-auction li .info .year {
  margin: 0;
}

.newest-auction li .info .milage {
  margin: 0;
}

.newest-auction li .info ul {
  display: table;
  width: 100%;
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.newest-auction li .info ul li {
  display: table-cell;
  font-size: 11pt;
  font-weight: 300;
  /* padding: 3px 0 3px 10px; */
  padding-bottom: 10px;
  text-align: center;
  color: #1e1e1e;
  border-right: 1px solid #fff;
}
/* button  of showing bids */
.bidding_history {
  font-size: 12px !important;
}
.bidding_history a {
  font-weight: 800;
}
.newest-auction li .info ul li small {
  margin-left: 4px;
  color: #c00;
}

.newest-auction li .info .title,
.newest-auction li .info .desc {
  padding: 0 10px;
}

.newest-auction li .info .price {
  position: absolute;
  top: 6px;
  right: 10px;
}

.newest-auction li .info .year {
  font-size: 11pt;
  position: absolute;
  top: 34px;
  right: 10px;
}

.newest-auction li .info .milage {
  position: absolute;
  top: 55px;
  right: 10px;
}

.newest-auction li .info ul {
  position: absolute;
  padding: 10px 0px;
  bottom: 0;
  left: 0;
  border-top: 1px solid #fff;
  background-color: #d9d9d9;
}

.sold-history li .info ul {
  padding: 0px 0px !important;
}

@media only screen and (max-width: 768px) {
 
  .newest-auction li .info .title {
    font-size: 14pt;
    margin: 0;
    color: #c00;
  }
  .newest-auction li .info .desc {
    font-size: 12pt;
    font-weight: 300;
    margin: 0;
  }
  .newest-auction li .info a {
    font-size: 12pt;
    margin: 0 10px;
  }
  .newest-auction li .info .price {
    font-size: 14pt;
    margin: 0;
    color: #c00;
  }
  .newest-auction li .info .year {
    position: absolute;
    top: 27px;
    font-size: 12pt;
  }
  .newest-auction li .info .milage {
    position: absolute;
    top: 50px;
    font-size: 12pt;
  }
  .newest-auction li .info ul li {
    display: table-cell;
    font-size: 11pt;
    font-weight: 300;
    padding: 3px 0 3px 10px;
    text-align: left;
    color: #1e1e1e;
    border-right: 1px solid #fff;
  }
}

@media only screen and (max-width: 480px) {
  
  .newest-auction li .info .title {
    font-size: 12pt;
    margin: 0;
    color: #c00;
  }
  .newest-auction li .info .desc {
    font-size: 10pt;
    font-weight: 300;
    margin: 0;
  }
  .newest-auction li .info a {
    font-size: 10pt;
    margin: 0 10px;
  }
  .newest-auction li .info .price {
    font-size: 12pt;
    margin: 0;
    color: #c00;
  }
  .newest-auction li .info .year {
    position: absolute;
    top: 25px;
    font-size: 10pt;
    margin: 0;
  }
  .newest-auction li .info .milage {
    position: absolute;
    top: 45px;
    font-size: 10pt;
    margin: 0;
  }
  .newest-auction li .info ul li {
    display: table-cell;
    font-size: 10pt;
    font-weight: 300;
    padding: 3px 0 3px 10px;
    text-align: left;
    color: #1e1e1e;
    border-right: 1px solid #fff;
  }
}

@media only screen and (max-width: 320px) {
  .newest-auction li img {
    width: 100%;
    height: 100%;
  }
  .newest-auction li .info .title {
    font-size: 17pt;
    margin: 0;
    color: #c00;
  }
  .newest-auction li .info .desc {
    font-size: 12pt;
    font-weight: 300;
    margin: 0;
  }
  .newest-auction li .info a {
    margin: 0 10px;
  }
  .newest-auction li .info .price {
    font-size: 17pt;
    margin: 0;
    color: #c00;
  }
  .newest-auction li .info .year {
    margin: 0;
  }
  .newest-auction li .info .milage {
    margin: 0;
  }
}

.btn-bid {
  padding-left: 0 !important;
  text-align: center !important;
  vertical-align: middle;
  border-right: none !important;
  background-color: #f4c23d;
  color: #222222 !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 0;
}

.btn-bid:hover {
  background-color: #336699 !important;
  color: rgb(255, 255, 255) !important;
}
.status .Ongoing {
  padding: 10px;
  color: green;
  font-weight: 700;
}
.status .Sold {
  padding: 10px;
  color: red;
  font-weight: 700;
}
#auction-detail-container {
  background: #deecfb;
  width: 97%;
  margin: 10px auto;

  padding: 20px;
  border: 1px solid rgb(232, 229, 229);
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-family: "Work Sans", sans-serif;
}
#auction-detail-container .table-column-name {
  text-transform: uppercase;
  width: 30%;
  font-weight: 700;
}
.seller-upload-form label {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
