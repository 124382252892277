.choose-frame-child {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.choose-frame-child,
.choose-frame-item,
.choose-rectangle-parent {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.choose-frame-item {
  background-color: rgba(51, 102, 153, 0.7);
  z-index: 1;
}
.choose-rectangle-parent {
  margin: 0 !important;
}
.choose-heading-2,
.choose-vivamus-gravida-magna {
  margin: 0;
  position: relative;
  z-index: 2;
}
.choose-heading-2 {
  width: 403px;
  font-size: inherit;
  line-height: 34px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.choose-vivamus-gravida-magna {
  align-self: stretch;
  font-size: 16px;
  line-height: 19.5px;
  color: #fff;
}
.choose-heading-2-why-choose-us-parent,
.choose-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.choose-heading-2-why-choose-us-parent {
  width: 516.3px;
  gap: 20px;
  max-width: 100%;
}
.choose-container {
  width: 100%;
  position: relative;
  padding: 105px 100px 105.5px;
  box-sizing: border-box;
  gap: 61px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 40px;
  color: #fff;
  font-family: "Work Sans";
}
@media screen and (max-width: 975px) {
  .choose-container {
    gap: 30px;
    padding-left: 156px;
    padding-right: 156px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 950px) {
  .choose-heading-2 {
    font-size: 32px;
    line-height: 27px;
  }
}
@media screen and (max-width: 700px) {
  .choose-container {
    gap: 15px;
    padding-left: 78px;
    padding-right: 78px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 500px) {
  .choose-frame-div {
    flex-direction: row;
    max-width: 100%;
  }
  .choose-container {
    padding: 20px 15px;
  }
}
@media screen and (max-width: 450px) {
  .choose-heading-2 {
    font-size: 24px;
    line-height: 20px;
  }
}
