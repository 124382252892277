.frame-parent .div {
  position: relative;
  line-height: 29px;
  font-weight: 500;
  display: inline-block;
  min-width: 11px;
}
.background-child,
.background-item {
  position: absolute;
  margin: 0 !important;
  right: 23px;
  bottom: -258.5px;
}
.background-child {
  height: 260px;
  width: 1.9px;
}
.background-item {
  height: 266px;
  width: 1.9px;
  bottom: -565.5px;
}
.background {
  align-self: stretch;
  border-radius: 24.42px;
  background-color: var(--color-goldenrod-200);
  border: 1px solid var(--color-goldenrod-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-mid) var(--padding-6xs)
    var(--padding-lg);
  position: relative;
}
.div1 {
  position: relative;
  line-height: 29px;
  font-weight: 500;
  display: inline-block;
  min-width: 11px;
}
.background1 {
  align-self: stretch;
  border-radius: 24.42px;
  background-color: var(--color-goldenrod-200);
  border: 1px solid var(--color-goldenrod-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-mid) var(--padding-6xs)
    var(--padding-lg);
}
.div2 {
  position: relative;
  line-height: 29px;
  font-weight: 500;
  display: inline-block;
  min-width: 11px;
}
.background-parent,
.background2 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.background2 {
  border-radius: 24.42px;
  background-color: var(--color-goldenrod-200);
  border: 1px solid var(--color-goldenrod-100);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-mid) var(--padding-6xs)
    var(--padding-lg);
}
.background-parent {
  flex-direction: column;
  gap: var(--gap-239xl);
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-gray-300);
  font-family: var(--font-roboto);
}
