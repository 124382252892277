.homepage-cars-layout {
  padding-left: 0;
  overflow-x: scroll; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.homepage-cars-layout::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 500px) {
  .homepage-cars-layout {
    display: flex !important;
  }
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .homepage-cars-layout::-webkit-scrollbar {
    display: none;
  }
}
