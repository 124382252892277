.dzire-card {
  width: 20rem;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  min-height: 448px;
  margin-top: 20px;
  margin-right: 15px;
}

.status-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #e5ffe5;
  color: #00e300;
  padding: 9px 14px;
  border-radius: 42px;
  font-size: 14px;
  font-weight: bold;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-body {
  padding: 20px;
}

.current-bid {
  width: fit-content;
  padding: 6px;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  color: #606060;
  margin-bottom: 14px;
}

.car-desc {
  font-size: 18px;
  color: #888888;
  margin-bottom: 10px;
}

.car-details .badge {
  margin-right: 5px;
  margin-bottom: 5px;
}

.badge {
  color: #465166;
  font-weight: 500;
  padding: 10px;
  border-radius: 6px;
  font-size: 15px;
  background-color: #f4f9f9;
}

.img-wrapper {
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
  overflow: hidden;
}

.img-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 450px) {
  .dzire-card {
    width: 17.5rem;
    min-height: 392px;
  }
  .card-title {
    font-size: 18px;
  }
  .current-bid {
    font-size: 14px;
  }
  .car-desc {
    font-size: 13px;
  }
  .badge {
    font-size: 12px;
  }
}
