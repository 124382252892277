.status-tooltip {
  position: relative;
  z-index: 10;
  /* top: 10px; */
  /* left: 10px; */
}

.status-popup {
  position: absolute;
  background-color: #ffffff;
  color: #ffffff;
  border: 1px solid #ccc;
  padding: 2px;
  /* borde/r-radius: 5px; */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  /* display:/ none; */
  font-size: 10px;
  cursor: pointer;
  /* display: inline-block; */
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  font-weight: 700;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.status-popup.NotStarted {
  background-color: darkblue;
}

.status-popup.Closed {
  background-color: #f49494;

  color: rgb(128, 13, 13);
}
.status-popup.Closed:hover {
  box-shadow: rgb(128, 13, 13, 0.25) 0 -25px 18px -14px inset,
    rgb(128, 13, 13, 0.25) 0 1px 2px, rgb(128, 13, 13, 0.25) 0 2px 4px,
    rgb(128, 13, 13, 0.25) 0 4px 8px, rgb(128, 13, 13, 0.25) 0 8px 16px,
    rgb(128, 13, 13, 0.25) 0 16px 32px;
}

.status-popup.Ongoing {
  background-color: #c2fbd7;

  color: green;
}
.status-popup.Ongoing:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.25) 0 1px 2px, rgba(44, 187, 99, 0.25) 0 2px 4px,
    rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
}
.status-tooltip:hover .status-popup {
  display: block;
}
